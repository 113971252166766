@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* @apply bg-gradient-to-r from-gradient-2 to-gradient-1 */
  @apply bg-[#2C3F1F] scrollbar-thumb-black scrollbar-track-transparent scrollbar-thin
};

body, input, button {
  @apply font-poppins
};

input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
